import React, { useState } from "react";
import "./BoardShowcase.scss";
import {
  DropdownMenu,
  Button,
  IconMagnifyingGlassLightning,
  IconCursorLines,
  IconPlus,
  Toolbar,
  IconMagnifyingGlass,
  IconRectangleArrowUpCenter,
  IconLinesThreeHorizontal,
  IconLayout,
  IconStickyNote,
  IconFrame,
  IconCursorFilled,
  IconBell,
  IconSparksFilled,
  IconFramePlay,
  IconBoard,
  IconPaintBucket,
  Flex,
} from "@mirohq/design-system";

const App = () => {
  const [selectedColor, setSelectedColor] = useState("item2");
  const colors = {
    item1: "#fff",
    item2: "#F2F2F2",
    item3: "#2E2E2E",
    item4: "#F6E4E6",
  };

  const handleColorChange = (value) => {
    setSelectedColor(value);
  };

  return (
    <Flex
      justify="center"
      css={{
        borderRadius: "8px",
        minHeight: "70vh",
        minWidth: "70vw",
        width: "100%",
        maxWidth: "1200px",
        position: "relative",
        backgroundColor: colors[selectedColor],
      }}
      direction="column"
      id="pattern2"
      align="center"
    >
      <div className="BoardDemoSticker">
        <p
          className="BoardDemoParagraph"
          style={{
            maxWidth: "735px",
            padding: "12px 0",
            lineHeight: "160%",
            margin: 0,
          }}
        >
          I resolved <b>38%</b> of cross-platform accessibility issues, reduced
          implementation time by <b>80%</b>, improved satisfaction, and shipped{" "}
          <b>1000+</b> component instances within first 3 months.
        </p>
        <p
          className="BoardDemoParagraph"
          style={{
            maxWidth: "735px",
            padding: "12px 0",
            lineHeight: "160%",
            margin: 0,
          }}
        >
          Senior Product Designer at Miro, Design System
        </p>
      </div>
      <Toolbar
        css={{
          position: "absolute",
          left: "16px",
          top: "16px",
          color: "var(--black)",
          gap: "var(--space-100)",
          alignItems: "center",
          padding: "0 var(--space-100)",
        }}
      >
        <Button
          css={{ padding: "8px !important" }}
          size="large"
          variant="ghost-subtle"
        >
          Portfolio Review
        </Button>
        <Toolbar.Separator />

        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Toolbar.Icon>
              <IconLinesThreeHorizontal />
            </Toolbar.Icon>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content sideOffset={8} align="start" id="bgcolor">
            <DropdownMenu.Item>
              <DropdownMenu.IconSlot>
                <IconMagnifyingGlassLightning />
              </DropdownMenu.IconSlot>
              Command palette
              <DropdownMenu.HotkeySlot>⌘ K</DropdownMenu.HotkeySlot>
            </DropdownMenu.Item>
            <DropdownMenu.Separator />

            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <DropdownMenu.IconSlot>
                  <IconBoard />
                </DropdownMenu.IconSlot>
                Board
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.Item>
                  <DropdownMenu.IconSlot>
                    <IconPlus />
                  </DropdownMenu.IconSlot>
                  New Board
                </DropdownMenu.Item>
                <DropdownMenu.Separator />

                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>
                    <DropdownMenu.IconSlot>
                      <IconPaintBucket />
                    </DropdownMenu.IconSlot>
                    Background color
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.RadioGroup
                      value={selectedColor}
                      onChange={(value) => handleColorChange(value)}
                    >
                      <DropdownMenu.RadioItem value="item1">
                        <DropdownMenu.IconSlot>
                          <div
                            className="colorBox"
                            style={{
                              backgroundColor: "#fff",
                              borderColor: "#949494",
                            }}
                          />
                        </DropdownMenu.IconSlot>
                        White
                      </DropdownMenu.RadioItem>
                      <DropdownMenu.RadioItem value="item2">
                        <DropdownMenu.IconSlot>
                          <div
                            className="colorBox"
                            style={{
                              backgroundColor: "#F2F2F2",
                              borderColor: "#949494",
                            }}
                          />
                        </DropdownMenu.IconSlot>
                        Light Gray
                      </DropdownMenu.RadioItem>
                      <DropdownMenu.RadioItem value="item3">
                        <DropdownMenu.IconSlot>
                          <div
                            className="colorBox"
                            style={{
                              backgroundColor: "#2E2E2E",
                              borderColor: "#1C1C1C",
                            }}
                          />
                        </DropdownMenu.IconSlot>
                        Dark Gray
                      </DropdownMenu.RadioItem>
                      <DropdownMenu.RadioItem value="item4">
                        <DropdownMenu.IconSlot>
                          <div
                            className="colorBox"
                            style={{
                              backgroundColor: "#F6E4E6",
                              borderColor: "#D6A8AE",
                            }}
                          />
                        </DropdownMenu.IconSlot>
                        Light Pink
                      </DropdownMenu.RadioItem>
                    </DropdownMenu.RadioGroup>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
          </DropdownMenu.Content>
        </DropdownMenu>
        <Toolbar.Icon id="hide3">
          <IconMagnifyingGlass />
        </Toolbar.Icon>
        <Toolbar.Icon id="hide2">
          <IconRectangleArrowUpCenter />
        </Toolbar.Icon>
      </Toolbar>

      <Toolbar
        orientation="vertical"
        css={{
          position: "absolute",
          left: "16px",
          top: "50%",
          transform: "translateY(-50%)",
          padding: "var(--space-100) 0",
        }}
        id="hide4"
      >
        <Toolbar.Icon>
          <IconCursorFilled />
        </Toolbar.Icon>
        <Toolbar.Icon>
          <IconLayout />
        </Toolbar.Icon>
        <Toolbar.Icon>
          <IconStickyNote />
        </Toolbar.Icon>
        <Toolbar.Icon>
          <IconFrame />
        </Toolbar.Icon>
      </Toolbar>

      <Toolbar
        id="hide1"
        css={{
          position: "absolute",
          right: "16px",
          top: "16px",
          padding: "0 var(--space-100)",
        }}
      >
        <Toolbar.Icon>
          <IconCursorLines />
        </Toolbar.Icon>
        <Toolbar.Icon>
          <IconBell />
        </Toolbar.Icon>
        <Button size="medium" variant="solid-subtle">
          Present
        </Button>
        <Button size="medium">Share</Button>
      </Toolbar>

      <Toolbar
        css={{
          position: "absolute",
          right: "16px",
          bottom: "16px",
          padding: "0 var(--space-50)",
        }}
      >
        <Toolbar.Icon>
          <IconSparksFilled />
        </Toolbar.Icon>
      </Toolbar>

      <Toolbar
        css={{
          position: "absolute",
          left: "16px",
          bottom: "16px",
          padding: "0 var(--space-50)",
        }}
      >
        <Toolbar.Icon>
          <IconFramePlay />
        </Toolbar.Icon>
      </Toolbar>
    </Flex>
  );
};

export default App;
